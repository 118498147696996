import React from 'react'
import Header from './Header'
import SocialItem from './SocialItem'

function TopHeader() {
    const getCurrentTime = () => {
        const now = new Date();
        const dayOfWeek = now.toLocaleDateString('en-US', { weekday: 'long' });
        const hour = now.getHours();
        const minute = now.getMinutes();
        let timeFormat = `${hour}:${minute < 10 ? '0' + minute : minute}`;
    
        if (hour >= 8 && hour < 18) {
          return `${dayOfWeek} 8:00 - 18:00`;
        } else if (dayOfWeek === 'Saturday' && hour >= 8 && hour < 14) {
          return `${dayOfWeek} 8:00 - 14:00`;
        } else {
          return 'Closed';
        }
      };
  return (
    <div className='heros_t'>
        <div className='top_social' >
            <div className='top_social_wraper'>
                <div className='con_flex'>
                    <SocialItem md icon='/images/clock.svg' desc='Mon - Fri 8:00 - 18:00 / Sarueday 8:00 - 14:00' />
                    <SocialItem sm  icon='/images/mail.svg' desc='sales@truewishenergy.com' />
                    <SocialItem md icon='/images/location.svg' desc='8 Babatunde Anjous, Off Admiralty Way, Lekki' />
                </div>
                <div className='con_flex'>
                    {/* <a className='social_style'  href='https://www.facebook.com/' target='_blank' >
                        <img src='/images/facebook.svg' alt=''/>
                    </a> */}
                    {/* <a className='social_style' href='https://twitter.com/home' target='_blank'>
                        <img src='/images/twitter.svg' alt=''/>
                    </a> */}
                    {/* <a className='social_style' href='https://www.youtube.com/' target='_blank'>
                    <img src='/images/youtube.svg' alt=''/>
                    </a> */}
                    <a className='social_style' href='https://www.instagram.com/truewishenergy/' target='_blank'>
                        <img src='/images/ins.svg' alt=''/>
                    </a>
                </div>
            </div>
        </div>
        <Header />
    
    </div>
  )
}

export default TopHeader