import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Hero from '../../components/common/Hero'
import ServiceAside from '../../components/services/ServiceAside'
import Footer from '../../components/footer/Footer'

function CapacityInverter() {
  return (
    <div className='services'>
        <TopHeader />

        <Hero 
        subtitle='services'
        title='Capacity Inverters'
        desc='This includes: Low capacitor inverter - Heiwa series,
        Heiwa iChallenger series. Medium capacitor inverter - 4.2 KVA / 48V,  5 KVA / 96V,  6.5 KVA / 48V. High capacitor inverter - 10KVA / 120V, 10KVA / 180V, 15KVA / 240V, 20KVA / 360V'
        img='/images/services/chero.jpg'
        />
        <section className='tech_main'>
            <div className='wrapper techflex'>
                <ServiceAside />
                <div className='serv_content_right'>
                    {/*  */}
                    <div className='serv_content1'>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Heiwa Series Inverter</h4>
                            <p className='servcon_desc'>
                            The Heiwa Series Inverter is a cutting-edge solar power inverter designed for residential and commercial applications. It boasts advanced features and technology to maximize energy production and system efficiency.<br/>
                            Key features of the Heiwa Series Inverter include:

                            <br/><br/>
                            Specifications for a 390W solar panel typically include:
                            <br/>
                            <ol>
                                <li>High Efficiency: The inverter is engineered to deliver high conversion efficiency, ensuring maximum power output from solar panels.</li>
                                <li>Robust Design: Built with durable components and materials, the Heiwa Series Inverter offers long-term reliability and performance.</li>
                                <li>Smart Monitoring: The inverter is equipped with smart monitoring capabilities, allowing users to track system performance and energy production in real-time.</li>
                                <li>Grid Compatibility: Designed to seamlessly integrate with the grid, the Heiwa Series Inverter ensures smooth operation and grid stability.</li>
                                <li>User-Friendly Interface: The inverter features an intuitive interface for easy installation, configuration, and monitoring.</li>
                                <li>Safety Features: With built-in safety features such as overvoltage and overload protection, the Heiwa Series Inverter prioritizes user safety and system protection.</li>

                            </ol>
                            Overall, the Heiwa Series Inverter is a dependable and efficient solution for harnessing solar energy for residential and commercial use.
                            </p>
                            <img src='/images/services/inverter1.svg' alt=''/>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h4 className='invert_spec'>Various type with Specifications</h4>
                            <h5 className='serv_group_title'>950VA / 12V</h5>
                            <ul className='serv_group_list'>
                                <li>Model - HEIWA 950VA/12V</li>
                                <li>Capacity - 950VA</li>
                                <li>Voltage (Input) - 100 VAC - 280 VAC ± 15 VAC</li>
                                <li>Max Bulb Load (Output)- 200 VAC ± 10%</li>
                                <li>Maximum charging current - 15Amp</li>
                                <li>Dimension (LXWXH) in CM - 38x33x21</li>
                            </ul>
                            <h5 className='serv_group_title'>1625VA / 12V</h5>
                            <ul className='serv_group_list'>
                                <li>Model - HEIWA 1625VA/12V</li>
                                <li>Capacity - 1625VA</li>
                                <li>Voltage (Input) - 100 VAC - 280 VAC ± 15 VAC</li>
                                <li>Max Bulb Load (Output) - 200 VAC ± 10%</li>
                                <li>Maximum charging current - 24Amp</li>
                                <li>Dimension (LXWXH) in CM - 115x157.5x64.6</li>
                            </ul>
                            <h5 className='serv_group_title'>1675VA / 24V</h5>
                            <ul className='serv_group_list'>
                                <li>Model - HEIWA 1675VA/24V: </li>
                                <li>Capacity - 1675VA</li>
                                <li>Voltage (Input) - 100 VAC - 280 VAC ± 15 VAC</li>
                                <li>Max Bulb Load (Output) - 200 VAC ± 10%</li>
                                <li>Maximum charging current - 17Amp</li>
                                <li>Dimension (LXWXH) in CM - 49.5x42x27</li>
                            </ul>
                            <h5 className='serv_group_title'>2050VA / 24V</h5>
                            <ul className='serv_group_list'>
                                <li>Model - HEIWA 2050VA/24</li>
                                <li>Capacity - 2050VA</li>
                                <li>Voltage (Input) - 100 VAC - 280 VAC ± 15 VAC</li>
                                <li>Max Bulb Load (Output) -  200 VAC ± 10%</li>
                                <li>Maximum charging current - 24Amp</li>
                                <li>Dimension (LXWXH) in CM - 50x41.5x26.5</li>
                            </ul>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                       
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Heiwa iChallenger Series Inverter</h4>
                            <p className='servcon_desc'>
                            The Heiwa iChallenger Series Inverter is an advanced solar power inverter designed for residential and commercial <br/> 
                            applications. It incorporates innovative features and cutting-edge technology to optimize energy production and <br/> 
                            system performance. <br/>
                            Key features of the Heiwa iChallenger Series Inverter include:
                            <ul>
                                <li>High Efficiency: The inverter is engineered to deliver exceptional conversion efficiency, maximizing power output from solar panels and enhancing overall system performance.</li>
                                <li>Intelligent MPPT: Equipped with intelligent Maximum Power Point Tracking (MPPT) technology, the iChallenger Series ensures efficient power capture from solar panels under varying environmental conditions.</li>
                                <li>Grid Interaction: The inverter seamlessly interacts with the grid, enabling users to utilize solar energy while maintaining connectivity to the utility grid. This allows for efficient energy management and grid support.</li>
                                <li>Enhanced Monitoring: With advanced monitoring capabilities, users can easily monitor system performance, energy production, and consumption in real-time through intuitive interfaces and mobile applications.</li>
                                <li>Durability and Reliability: Built with high-quality components and robust construction, the Heiwa iChallenger Series Inverter offers long-term durability and reliability, ensuring consistent operation and minimal maintenance requirements.</li>
                                <li>Safety Features: The inverter incorporates comprehensive safety features such as overvoltage protection, overcurrent protection, and short-circuit protection to safeguard both the system and users.</li>

                            </ul>

                            Overall, the Heiwa iChallenger Series Inverter provides a reliable and efficient solution for harnessing solar energy, offering exceptional performance and user-friendly operation for residential and commercial solar installations.
                            </p>
                            <img src='/images/services/inverter2.svg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h4 className='invert_spec'>Various type with Specifications</h4>
                            <h5 className='serv_group_title'>1100VA / 12V</h5>
                            <ul className='serv_group_list'>
                                <li>Capacity - 1100VA</li>
                                <li>Voltage (Input) -  100 VAC - 280 VAC ± 15</li>
                                <li>Maximum charging current: 15Amp</li>
                                <li>Gross Weight (in Kg) - 9.15kg</li>
                                <li>Dimension (LXWXH) in CM  - 32.5x34x18.5</li>
                                
                            </ul>
                            <h5 className='serv_group_title'>1500VA / 24V</h5>
                            <ul className='serv_group_list'>
                                <li>Capacity - 1500VA</li>
                                <li>Voltage (Input) - 100 VAC - 280 VAC ± 15</li>
                                <li>Maximum charging current:  15Amp</li>
                                <li>Gross Weight (in Kg) - 16.31kg</li>
                                <li>Dimension (LXWXH) in CM  - 43.99x41.3x27</li>
                                
                            </ul>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>2.5KVA capacitor inverter</h4>
                            <p className='servcon_desc'>
                            2.5KVA capacitor inverter provides a reliable and efficient solution for powering electrical loads in off-grid or grid-tied solar energy systems, offering stable and uninterrupted power supply for various residential or commercial applications.
                            </p>
                            <img src='/images/services/inverter3.svg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of a 2.5KVA capacitor inverter may include:</h5>
                            <ol className='serv_group_list'>
                                <li>Power Output: A 2.5KVA capacitor inverter is capable of delivering up to 2.5 kilovolt-amps of power to electrical loads. This makes it suitable for small to medium-sized residential or commercial applications.</li>
                                <li>Voltage Compatibility: Operating at a specific voltage, such as 48V or 96V, this inverter requires a compatible battery bank to function efficiently. It is important to ensure that the inverter voltage matches the battery bank voltage for optimal performance.</li>
                                <li>Efficiency: Capacitor inverters are known for their high efficiency in converting DC power from solar panels or batteries into AC power for use in homes or businesses. This helps to minimize energy loss and maximize the utilization of available power sources.</li>
                                <li>Durability: Many capacitor inverters are built with robust components and sturdy construction to ensure long-term reliability and performance, even in harsh environmental conditions.</li>
                                <li>Safety Features: These inverters often come equipped with advanced safety features such as overload protection, short-circuit protection, overvoltage protection, and overheat protection to safeguard both the inverter and connected devices.</li>
                                <li>Remote Monitoring: Some capacitor inverters offer remote monitoring capabilities, allowing users to access real-time performance data, adjust settings, and receive alerts or notifications remotely via communication interfaces such as Wi-Fi, Ethernet, or RS485.</li>
                            </ol>
                            
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>4.2KVA / 48V capacitor inverter</h4>
                            <p className='servcon_desc'>
                            Capacitor inverters, such as the 4.2KVA / 48V model, offer efficient and reliable power conversion for medium-sized <br/> residential or commercial solar energy systems. With a capacity of 4.2 kilovolt-amps (KVA) and operating at 48 volts (V), <br/> these inverters are suitable for powering various household appliances, office equipment, or small businesses. <br/>
                            Overall, a 4.2KVA / 48V capacitor inverter provides an efficient and versatile solution for powering electrical loads in off-grid or grid-tied solar energy systems, offering reliable performance and flexibility for various applications.
                            </p>
                            <img src='/images/services/inverter4.svg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of a 4.2KVA / 48V capacitor inverter may include:</h5>
                            <ol className='serv_group_list'>
                                <li>High Efficiency: Capacitor inverters are known for their high efficiency, converting DC power from solar panels into AC power for use in homes or businesses with minimal energy loss.</li>
                                <li>Robust Design: These inverters are typically built with durable components and sturdy construction, ensuring reliability and longevity even in challenging environmental conditions.</li>
                                <li>Grid Interaction: Some capacitor inverters may have grid-tie capabilities, allowing them to synchronize with the utility grid to supplement energy needs or feed excess power back into the grid.</li>
                                <li>Battery Compatibility: Operating at 48 volts, the 4.2KVA / 48V capacitor inverter is compatible with a variety of battery types, including lead-acid, lithium-ion, or gel batteries, for energy storage and backup power.</li>
                                <li>User-Friendly Interface: Many capacitor inverters feature user-friendly interfaces with LCD displays or digital screens, allowing users to monitor system performance, settings, and energy consumption easily.</li>
                                <li>Safety Features: Capacitor inverters often incorporate safety features such as overload protection, short-circuit protection, and overvoltage protection to ensure safe and reliable operation.</li>
                            </ol>  
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>5KVA / 96V capacitor inverter</h4>
                            <p className='servcon_desc'>
                            The 5KVA / 96V capacitor inverter is a robust and efficient power conversion device designed for medium to <br/>
                            large-scale residential or commercial solar energy systems. With a capacity of 5 kilovolt-amps (KVA) and operating at <br/>
                             96 volts (V), these inverters are capable of delivering reliable and stable AC power for a wide range of electrical loads. <br/>
                             A 5KVA / 96V capacitor inverter offers a reliable, efficient, and flexible solution for powering electrical loads in off-grid or <br/>
                              grid-tied solar energy systems, providing users with stable and uninterrupted power supply for their homes or businesses.
                            </p>
                            <img src='/images/services/inverter5.svg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of a 5KVA / 96V capacitor inverter may include:</h5>
                            <ol className='serv_group_list'>
                                <li>High Power Output: The 5KVA rating of the inverter allows it to handle significant electrical loads, making it suitable for powering multiple appliances, lighting systems, and other equipment simultaneously.</li>
                                <li>Dual Battery System: Operating at 96 volts, the inverter typically requires two 48-volt battery banks connected in series to provide sufficient voltage for operation. This configuration allows for greater energy storage capacity and flexibility.</li>
                                <li>Advanced Protection Features: Capacitor inverters often incorporate advanced protection features such as overload protection, short-circuit protection, overvoltage protection, and overheat protection to safeguard both the inverter and connected devices from damage.</li>
                                <li>Grid Interaction: Some capacitor inverters may offer grid-tie capabilities, allowing them to synchronize with the utility grid to supplement energy needs or export excess power back to the grid, thus reducing electricity costs or generating revenue through net metering.</li>
                                <li>Remote Monitoring and Control: Many capacitor inverters can be monitored and controlled remotely using communication interfaces such as Wi-Fi, Ethernet, or RS485, enabling users to access real-time performance data, adjust settings, and receive alerts or notifications remotely.</li>
                                <li>Compact and Efficient Design: Despite their high power output, capacitor inverters are often designed to be compact and space-saving, making them suitable for installation in various indoor or outdoor locations.</li>
                            </ol>  
                        </div>
                    </div>

                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>6.5KVA / 48V capacitor inverter</h4>
                            <p className='servcon_desc'>
                            6.5KVA / 48V capacitor inverter provides a reliable and efficient solution for powering electrical loads in off-grid or <br/>  
                            grid-tied solar energy systems, offering stable and uninterrupted power supply for residential or commercial applications.
                            </p>
                            <img src='/images/services/inverter6.svg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of a 6.5KVA / 48V capacitor inverter may include:</h5>
                            <ol className='serv_group_list'>
                                <li>Power Output: A 6.5KVA capacitor inverter is capable of delivering up to 6.5 kilovolt-amps of power to electrical loads. This makes it suitable for medium to large-scale residential or commercial applications.</li>
                                <li>Voltage Compatibility: Operating at 48 volts, this inverter requires a 48V battery bank to function efficiently. It is compatible with various battery types, including lead-acid, lithium-ion, or gel batteries.</li>
                                <li>Efficiency: Capacitor inverters are known for their high efficiency in converting DC power from solar panels or batteries into AC power for use in homes or businesses. This helps to minimize energy loss and maximize the utilization of available power sources.</li>
                                <li>Durability: Many capacitor inverters are built with robust components and sturdy construction to ensure long-term reliability and performance, even in harsh environmental conditions.</li>
                                <li>Safety Features: These inverters often come equipped with advanced safety features such as overload protection, short-circuit protection, overvoltage protection, and overheat protection to safeguard both the inverter and connected devices.</li>
                                <li>Remote Monitoring: Some capacitor inverters offer remote monitoring capabilities, allowing users to access real-time performance data, adjust settings, and receive alerts or notifications remotely via communication interfaces such as Wi-Fi, Ethernet, or RS485.</li>
                                <li>Grid Interaction: Depending on the model, capacitor inverters may offer grid-tie capabilities, enabling them to synchronize with the utility grid to supplement energy needs or export excess power back to the grid.</li>
                            </ol>  
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default CapacityInverter