import React from 'react'

function ServiceSteps({step,num,title,desc}) {
  return (
    
    <div className='stepsItem_container'>
        <div className='stepsItem'>
            <div className='stepsItem_inner'>
                <div className='steps_subhead'>
                    <h6 className='steps_subhead_t'>{step}</h6>
                    <img src={num} alt=''/>
                </div>
                <h3 className='steps_title'>{title}</h3>
                <p className='steps_desc'>{desc}</p>
            </div>
        </div>
        <img className='circlStep' src='/images/services/circleArrow.svg' alt=''/>
    </div>
   
  )
}

export default ServiceSteps