import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Hero from '../../components/common/Hero'
import Footer from '../../components/footer/Footer'
import ServiceAside from '../../components/services/ServiceAside'

function TechnicalService() {
  return (
    <div className='services'>
        <TopHeader />


        <Hero 
        subtitle='services'
        title='Technical Services'
        desc='Expert assistance with equipment, systems, or processes to ensure optimal performance.'
        img='/images/services/thero.jpg'
        />

        <section className='tech_main'>
            <div className='wrapper techflex'>
                <ServiceAside />
                <div className='serv_content_right'>
                    {/*  */}
                    <div className='serv_content1'>
                        <p className='serv_step'>Step 01</p>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Inspection & Load Estimation</h4>
                            <p className='servcon_desc'>Our team will evaluate your requirements, considering factors like load, usage patterns, essential appliances, and budget, in order to suggest the most suitable inverter. If needed, we'll perform a site inspection and load estimation to optimize your backup solution..</p>
                            <img src='/images/services/lense.jpg' alt=''/>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <p className='serv_step'>Step 02</p>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Delivery & Installation</h4>
                            <p className='servcon_desc'>We advise utilizing a Truewish energy Service approved installer for your installation. Our installers will guide you on inverter operation and conditions for optimal use, including which appliances are not suitable for use with an inverter system and tips for battery longevity. They'll ensure you fully benefit from your purchase. Additionally, delivery services are available upon request.</p>
                            <img src='/images/services/lorry.jpg' alt=''/>
                        </div>
                    </div>

                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <p className='serv_step'>Step 03</p>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Premium Service & Support</h4>
                            <p className='servcon_desc'>Our technical team offers round-the-clock support should you require assistance with your solar product.</p>
                            <img style={{marginTop:'30px'}} src='/images/services/techphoto.jpg' alt=''/>
                        </div>
                    </div>
                    {/*  */}
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default TechnicalService