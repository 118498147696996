import React from 'react'
import { Link } from 'react-router-dom'

function ServiceAside() {
  return (
    <div className='tech_main_left'>
        <div className='tech_service_list'>
            <div className='tech_main_left_inner'>
                <h4 className='tech_serv_title'>Services</h4>

                <div className='tech_serv_content'>
                    <Link to='/technical-services' className='tech_serv_num'>
                        <span>01</span>
                        <span>Technical Services</span>
                    </Link>

                    <Link to='/solar-product' className='tech_serv_num'>
                        <span>02</span>
                        <span>Solar Product</span>
                    </Link>

                    <Link to='/capacity-inverters' className='tech_serv_num'>
                        <span>03</span>
                        <span>Capacity Inverters</span>
                    </Link>

                    <Link to='/batteries' className='tech_serv_num'>
                        <span>06</span>
                        <span>Batteries</span>
                    </Link>

                </div>

                <h4 className='tech_serv_title'>need help?</h4>
                <p className='tech_serv_desc'>Our Client care managers are on call 24/7 to answer your questions representative or submit a business inquiry online.</p>

                <div className='tech_serv_phone'>
                    <img src='/images/services/phone.svg' alt='' />
                    <p  className='tech_serv_phone_text'>+234-901-097-8618</p>
                </div>
            </div>
        </div>
        <img src='/images/services/ad.jpg' alt=''/>
    </div>
  )
}

export default ServiceAside