import React from 'react'
import { useNavigate } from 'react-router-dom'

function ServiceItem({num,title,desc,link}) {
    const navigate  = useNavigate()
    const handleClick = ()=>{
        const path = link;
        navigate(path);
    }
  return (
    <div className='service_item'>
        <div className='service_item_inner'>
            <div className='flex_content'>
                <div className='service_item_content'>
                    <span>{num}</span>
                    <h6 className='service_item_title'>{title}</h6>
                </div>
                <p className='service_item_desc'>{desc}</p>
            </div>

            <div className='serv_arrow' onClick={handleClick}>
                <p className='servic_readmore'>read more</p>
                <img src='/images/services/circleArrow.svg' alt=''/>
            </div>
        </div>
    </div>

  )
}

export default ServiceItem