import React from 'react'

function Sec4Item({icon,num,title,desc}) {
  return (
    <div className='sec4_item'>
        <img src={icon} alt=''/>
        <div className='sec4_t'>
        <span>{num}</span>
        <p className='sec4_item_title'>{title}</p>
        </div>
        <p className='sec4_item_desc'>{desc}</p>
    </div>
  )
}

export default Sec4Item