import React from 'react'
import './Footer.scss'
function FooterContactItem({icon,title,desc}) {
  return (
    <div className='footer_contact'>
        <img className='footer_img' src={icon} alt=''/>
        <div className='footer_contact_text'>
            <h4 className='footer_contact_title'>{title}</h4>
            <p className='footer_contact_desc'>{desc}</p>
        </div>
    </div>
  )
}

export default FooterContactItem