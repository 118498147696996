import React, { useState } from 'react'
import './project.scss'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Footer from '../../components/footer/Footer'
import ProjectItem from '../../components/projects/ProjectItem'
import Hero from '../../components/common/Hero'
import { changeCpage, nextPage, prePage } from '../../components/utilities/pagination/pagination'

import projectData from '../../components/projects/projectData.json'
function Project() {
    const [currentPage,setCurrentPage] = useState(1)

    let allProjectData = projectData;
 
    const recordsPerPage = 3;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = allProjectData.slice(firstIndex,lastIndex);
    const nPage = Math.ceil(allProjectData.length/recordsPerPage);
    const numbers = [...Array(nPage + 1).keys()].slice(1)
  return (
    <div className='project'>
        <TopHeader />
        <Hero 
        subtitle='latest projects'
        title='Projects With Title'
        desc='It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.'
        img='/images/projects/hero2.jpg'
        />

        <section className='psec2'>
            {
                records.map((item,index)=>(

                    <ProjectItem 
                    img={item.image}
                    title={item.title}
                    desc={item.desc}
                    btnText={item.btnText}
                    />
                ))
            }
            
        </section>

        <div className='pageNumbers'>
            {
              numbers.length > 0 && numbers.map((n,i)=>(
                <span key={i} 
                className={`page_num ${currentPage === n ? 'active':''}`}
                onClick={()=>changeCpage(setCurrentPage,n)}
                >
                  {n}
                </span>
              ))
            }

            <div className='next_btn' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
                <img src='/images/projects/greaterthan.svg' alt='' />
             </div> 

          </div> 

        <Footer />
    </div>
  )
}

export default Project