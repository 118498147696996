import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import './error.scss'
import Footer from '../../components/footer/Footer'
function Error() {
  return (
    <div className='services'>
        <TopHeader />
        <section className='error'>
           <img style={{marginTop:'160px'}} src='/images/404.svg' alt=''/>
           <h2 className='error_msg'>Whoops...page not found</h2>
           <div className='btn_error'>
                <img src='/images/close.svg' alt=''/>
                <button className='btn'>Back to Homepage</button>
           </div>
        </section>
        <Footer />

    </div>
  )
}

export default Error