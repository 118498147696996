import React from 'react'

function ProjectItem({img,title,desc,btnText}) {
  return (
    <div className='proj_wrapper'>
        <img src={img} alt=''/>
        <div className='proj_content'>
            <div className='proj_content_inner'>
                <h4 className='proj_content_title'>{title}</h4>
                <p className='proj_content_desc'>{desc}</p>
                <button className='btn'>{btnText}</button>
            </div>

        </div>
    </div>
  )
}

export default ProjectItem