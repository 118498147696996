import React from 'react'

function SocialItem({icon,desc,md,sm}) {
  return (
    <div className={`top_social_items ${ md ? 'turnoff':''} ${ sm ? 'turnoffleft':''}`}>
        <img src={icon} alt='' />
        <span>{desc}</span>
    </div>
  )
}

export default SocialItem