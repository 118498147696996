import React, { useState } from 'react'
import FooterContactItem from './FooterContactItem'
import { Link } from 'react-router-dom';
import './Footer.scss'

function Footer() {
    const [email,setEmail] = useState('')
    const handleChange= (e)=>{
        setEmail(e.target.value);
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        console.log("email: ", email);
    }
  return (
    <section className='bottom'>
        <div className='wrapper' style={{marginTop:'50px'}}>

          <div className='footer_top'>

              <img src='/images/footerlogo.svg' alt=''/>
              <FooterContactItem
              icon='/images/footerphoneRing.svg'
              title='+234 901 097 8618'
              desc='Call Us For Fast Support'
              />

              <FooterContactItem 
              icon='/images/footermail.svg'
              title='sales@truewishenergy.com'
              desc='Email Us For Query'
              />

              <FooterContactItem 
              icon='/images/footermap.svg'
              title='8 Babatunde Anjous, Off Admiralty Way'
              desc='Lekki phase 1, Lagos'
              />
          

          </div>

          <div className='footer_line'></div>

          <div className='footer_links'>
                <div className='footer_company'>
                    <h4 className='footer_company_title'>About Company</h4>
                    <p className='footer_company_desc'>Creating an awesome website with this Theme is easy than you can imagine. Get Solarta and discover home services today.</p>
                    <form onSubmit={handleSubmit} className='form_email'>
                        <input className='input_email' type='email' name='email' onChange={handleChange}/>
                        <button className='btn'>
                            Subscribe
                        </button>
                    </form>
                </div>

                <div className='link_container'>
                    <div className='useful_links'>
                        <p className='footer_link_header'>Useful Links</p>
                        <Link className='link_items' to="/">Home</Link>
                        <Link className='link_items' to="/about">About Us</Link>
                        <Link className='link_items' to="/projects">Projects</Link>
                        <Link className='link_items' to="/contact-us">Contact Us</Link>
                        <Link className='link_items' to="/404">Shop</Link>
                    </div>

                    {/*  */}
                    <div className='useful_links'>
                        <p className='footer_link_header'>Follow Us</p>
                        {/* <a className='link_items' href='https://www.facebook.com/'>Facebook</a> */}
                        <a className='link_items' href='https://www.instagram.com/truewishenergy/'>Instagram</a>
                        {/* <a className='link_items' href='https://twitter.com/home'>Twitter</a>
                        <a className='link_items' href='https://www.linkedin.com/in/'>Linkedin</a>
                        <a className='link_items' href='https://dribbble.com/'>Dribbble</a> */}
                    </div>
                    <div className='useful_links news_link'>
                        <p className='footer_link_header'>Latest Post</p>
                        <div className='link_post'>
                            <p className='link_items'>Truewish energy is a simple and flexible</p>
                            <span>15 July, 2021</span>
                        </div>
                        <div className='link_post'>
                            <p className='link_items'>What our client say about us</p>
                            <span>17 July, 2021</span>
                        </div>
                        <div className='link_post'>
                            <p className='link_items'>Tons of Shortcodes & Features</p>
                            <span>17 July, 2021</span>
                        </div>
                       
                    </div>

                </div>
          </div>

          <div className='footer_line'></div>

          <p  className='copywrite'>Copywriting © 2024 Truewish energy. All rights reserved</p>

        </div>
      </section>
  )
}

export default Footer