import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";

import './Header.scss'



const Navbar = ({sticky,mobile}) => {
   
    const [activeFilter, setActiveFilter] = useState('home');
    const handleClick = (filter) => {
        setActiveFilter(filter);
        
      };
   
    return (
        <nav className="mainmenu-nav">
            <div className="mainmenu">    
                <ul className='main_menu_nav'>
                    <li >
                        <Link 
                         to="/projects"
                         onClick={() => handleClick("projects")}
                          className={`${(sticky||mobile)?"stick":""} ${activeFilter === "projects" ? "active" : ""}`}
                        >
                            Projects

                        </Link>
                    </li>
                    <li>
                        <Link 
                        to="/services"
                        onClick={() => handleClick("services")}
                          className={`${(sticky||mobile)?"stick":""} ${activeFilter === "services" ? "active" : ""}`}
                        >
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link 
                        to="/about"
                        onClick={() => handleClick("about")}
                          className={`${(sticky||mobile)?"stick":""} ${activeFilter === "about" ? "active" : ""}`}
                        >
                            About
                        </Link>
                    </li>
                  
                    <li>
                        <Link 
                        to="/404"
                        onClick={() => handleClick("blog")}
                        className={`${(sticky||mobile)?"stick":""} ${activeFilter === "blog" ? "active" : ""}`} 
                        >
                            Blog
                        </Link>
                    </li>      
                    <li>
                        <Link 
                        to="/contact-us"
                        onClick={() => handleClick("contact-us")}
                        className={`${(sticky||mobile)?"stick":""} ${activeFilter === "contact-us" ? "active" : ""}`} 
                        >
                            Contact us
                        </Link>
                    </li>      
                   
                </ul>  
                
               
                
            </div>
            
        </nav>
        
    )
}

export default Navbar;