import React from 'react'
import './services.scss'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Hero from '../../components/common/Hero'
import ServiceItem from '../../components/services/ServiceItem'
import ServiceSteps from '../../components/services/ServiceSteps'
import Footer from '../../components/footer/Footer'
function Services() {
  return (
    <div className='services'>
        <TopHeader />


        <Hero 
        subtitle='services'
        title='Services That’s Right For You.'
        desc='It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.'
        img='/images/services/hero3.jpg'
        />

        {/*  */}
        <section className='sec_1'>
            <img src='/images/serviceBg.svg' alt=''/>

            <div className='service_list'>
               

                <ServiceItem 
                num='01'
                title='Technical Services'
                desc='Expert assistance with equipment, systems, or processes to ensure optimal performance.'
                link='/technical-services'
                />
                <ServiceItem 
                num='02'
                title='Solar Product'
                desc='This includes: Solar Panel 390Watts,
                DC Solar System'
                link='/solar-product'
                />
                <ServiceItem 
                num='03'
                title='Capacity Inverters'
                desc='This includes: Low capacitor inverter - Heiwa series,
                Heiwa iChallenger series. Medium capacitor inverter - 4.2 KVA / 48V,  5 KVA / 96V,  6.5 KVA / 48V. High capacitor inverter - 10KVA / 120V, 10KVA / 180V, 15KVA / 240V, 20KVA / 360V'
                link='/capacity-inverters'
                />
                <ServiceItem 
                num='04'
                title='Batteries'
                desc='This includes: Carbon Graphite Expander'
                link='/batteries'
                />
            </div>
        </section>

        <section className='sec_3'>
                <img className='img_s' src='/images/services/sinvest.jpg'  alt=''/>
                <div className='sec_3_invest'>
                    <div className='sec_3_invest_inner'>
                        <img className='invest_invisible' src='/images/services/investmentsbg.svg' alt='' />
                        <p className='sec_3_about'>ABOUT COMPANY</p>
                        <h4 className='sec_3_title'>Invest In Our Company & Save the Planet</h4>
                        <p className='sec_3_desc'>We believe we can make a difference to this world, to this very earth on which we live. We have been destroying the forests for whatever reasons for many years.  Our team consists of the great professionals with years of relevant experience. </p>
                        <div className='sec_3_rating'>
                            <div className='sec_3_rating_value'>
                               <div className='sec3_r_num'>
                                    <h2>99.5</h2>
                                    <img src='/images/services/ratings.svg' alt=''/>
                               </div>
                               <h5 className='sec_3_r_text'>Average Customers Rating</h5>
                            </div>
                            <button className='btn' style={{height:'40px'}}>Contact Us</button>
                        </div>
                    </div>
                </div>
            
        </section>

        <section className='sec_4'>
            <div className='wrapper'>
                <div className='sec_4_process'>
                    <img src='/images/services/process.svg' alt='' />
                    <p className='sec_4_p_subtitle'>what we do</p>
                    <h5 className='sec_4_p_title'>Our Work Process</h5>
                </div>

                <div className='sec_4_steps'>
                   
                        <ServiceSteps 
                        step='Step 01'
                        num='/images/services/step1.svg'
                        title='Inspection & Load Estimation'
                        desc="We'll customize your ideal inverter solution based on your needs and budget, with an optional site inspection."
                        />
                        <ServiceSteps 
                        step='Step 02'
                        num='/images/services/step2.svg'
                        title='Delivery & Installation'
                        desc="Choose a Truewish energy Service approved installer for expert guidance on maximizing your inverter investment."
                        />
                        <ServiceSteps 
                        step='Step 03'
                        num='/images/services/step3.svg'
                        title='Premium Service & Support'
                        desc="Our technical team offers round-the-clock support should you require assistance with your solar product."
                        />
                </div>


            </div>
        </section>

        <section className='sec_5'>
                <div className='sec_5_left'>
                    <div className='sec_5_connected'>
                        <img className='contact_serv' src='/images/services/contactbg.svg' alt=''/>
                        <p className='sec_5_conText'>stay connected</p>
                    </div>
                    <h4 className='sec_5_con'>Get in touch with us <br/> today</h4>
                    <form className='form_contact'>
                        <div>
                            <p className='form_label'>YOUR NAME (REQUIRED)</p>
                            <input className='form_input_contact' type='text'  />
                        </div>

                        <div>
                            <p className='form_label'>YOUR EMAIL (REQUIRED)</p>
                            <input className='form_input_contact' type='text'  />
                        </div>
                        <div>
                            <p className='form_label'>YOUR MESSAGE</p>
                            <textarea className='form_input_contact'>

                            </textarea>
                           
                        </div>

                        <button  className='btn' style={{marginTop:'40px'}}>Send Message</button>
                    </form>
                </div>
        </section>
                {/* <img className src='/images/services/address.svg' alt=''/> */}

        <Footer />

    </div>
  )
}

export default Services