import React, { useState } from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/merchantLanding/header/Header'
import './commingsoon.scss'
function ComingSoon() {
 
  const [isVisible, setIsVisible] = useState(false);
  
  
  return (
    <div className='landing'>
        <div className='single_hero'>
        
        <Header />
        <div className={`content_heros ${isVisible ? 'fadeIn' : ''}`}>
          <div className='landing_container'>
           
            <p className='comingsoon_title' >
              Coming Soon
            </p>
          </div>
        </div>
        <div class="custom-shape-divider-bottom-1711791218">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
          </svg>
        </div>
        </div>
        
        <Footer />
    </div>
  )
}

export default ComingSoon
