import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/Footer'
import './landings.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import TopHeader from '../../components/merchantLanding/header/TopHeader';
import TrustServiceItem from '../../components/landing/TrustServiceItem';
import Sec4Item from '../../components/landing/Sec4Item';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import BlogItem from '../../components/landing/BlogItem';
import FooterContactItem from '../../components/footer/FooterContactItem';
import '../../components/animate.scss'
import { Link } from 'react-router-dom';
function LandingPage() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);
  return (
    <div className='landing'>
      <TopHeader />
      <section className='land_sec1'>
        <div className='sec1_wrapper'>
          <p className='subtitle'>reduce pollution</p>
          <h3 className='title'>Maximize Saving Resources.</h3>
          <div className='sec1_sec'>
            <div className='learn_more'>
              <p className='learn_more_text'>
                We're confident in our ability to make a difference in our world, on our beloved Earth. The ongoing depletion of forests for various reasons has been a long-standing issue.
              </p>
              <Link to='/services' className='arrow_more'>
                <p className='more_text'>Learn More</p>
                <img src='/images/arrowUp.svg' alt=''/>
              </Link>
            </div>
           
            <img src='/images/hero1.jpg' alt=''/>
         
          </div>

        </div>
      </section>
      <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={100} animateOnce={true}> 
      <section className='sec2'>
        <img src='/images/slid.jpg' alt=''/>
        <TrustServiceItem icon='/images/hand.svg' title='Trusted Services' desc='We are trusted by our clients.'/>
        <TrustServiceItem icon='/images/headphone.svg' title='Trusted Services' desc='We are trusted by our clients.'/>
        <TrustServiceItem icon='/images/shape.svg' title='Trusted Services' desc='We are trusted by our clients.'/>
      </section>
      </AnimationOnScroll>
      <section className='sec3'>
         <div className='wrapper sec3_flex'>
            <div className='sec3_left'>
                <h6 className='sec3_subtitle'>ABOUT COMPANY</h6>
                <h2 className='sec3_title'>Produce Your Own Clean Save The Environment</h2>
                <p className='sec3_desc'>We are committed to making a positive impact on our planet, the very earth we call home. For far too long, forests have been depleted for various reasons. Our team comprises highly skilled professionals with years of relevant experience. We have an impressive portfolio of successful cases that we are eager to share.</p>
                <Link to='/services' className='btn' style={{background:'#FEF0E9',color:'#F26622'}}>
                  Learn More
                </Link>
            </div>
            <AnimationOnScroll animateIn="fadeInRight" duration={2} delay={100} animateOnce={true}> 
            <img src='/images/charts.png' alt=''/>
            </AnimationOnScroll>
            
         </div>
      </section>
      <section className='sec4'>
        <div className='sec4_container'>

        <div className='wrapper sec4_pos'>
            <div className='se4_top'>
                <h6 className='sec4_subtitle'>why choose us</h6>
                <h6 className='title'>Benefits to Save Energy</h6>
            </div>
            
            <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={100} animateOnce={true}> 
              <div className='sec4_list'>
                
                  <Sec4Item icon='/images/spanner.svg' num='0 1' title='Renewable Energy' desc='Sustainable sources, naturally replenished, reduce emissions and reliance on fossil fuels.' />
                  <Sec4Item icon='/images/feather.svg' num='0 2' title='Always Clean Energy' desc='Our commitment to clean energy ensures a sustainable future for generations.' />
                  <Sec4Item icon='/images/pentool.svg' num='0 3' title='Easy Installation' desc='We simplify procedures and offer expert guidance for adopting clean energy, contributing to a greener future.' />
                  <Sec4Item icon='/images/cloud.svg' num='0 4' title='Life Sustainable' desc='The sustainable life of solar energy contributes to a cleaner environment and a brighter future.' />
              </div>
            </AnimationOnScroll>
        </div>
        </div>
        <AnimationOnScroll animateIn="zoomIn" duration={2} delay={100} animateOnce={true}> 
            <img className='sec4_img' src='/images/cta.jpg' alt='' />
          </AnimationOnScroll>
      </section>

      <section className='sec5'>
              <img className='sec5_Project' src='/images/page_title.svg' alt=''/>
           
              <div className='project_slide'>
                
                    <img  src='/images/proja.jpg' alt=''/>    
                  
                      <img className='proj' src='/images/projb.jpg' alt=''/>     
                  
                    <img className='proj' src='/images/projc.jpg' alt=''/>     
                 
                    <img className='proj' src='/images/projd.jpg' alt=''/>     
                  
                    <img className='proj' src='/images/projd.jpg' alt=''/>     
              
              </div>

              
      </section>

      <section className='sec6'>
        <div className='wrapper dflex'>
          <div className='sec6_left'>
            <div className='sec6_faq'>
                <h6 className='sec6_subtitle'>ABOUT COMPANY</h6>
            </div>
            <h4 className='sec6_title'>Frequently Asked <br/> Questions</h4>
            <p className='sec6_desc'>The most comprehensive complete-system warranty in the solar industry. Hassle-free replacement, repair or reimbursement.</p>
            <p className='sec6_desc'> Any Question? <span>support@truewishenergy.com</span> </p>
          </div>

          <div className='sec6_right'>
            <div className='sec6_pay'>
              <h6 className='sec6_pay_title'>Can I pay with a credit card?</h6>
              <img src='/images/minus.svg' alt=''/>
            </div>
            <p className='sec6_right_desc'>This includes the frame which provides structural rigidity to the panel, the glass which protects the top side of the panel,  the EVA which is the glue that binds all the components of the panel, the cells where sunlight is converted to electricity and finally the junction box and connectors which transfers energy from one panel to the next.</p>
            <div className='sec6_pay'>
              <h6 className='sec6_pay_title'>Technical team of enthusiastic?</h6>
              <img src='/images/chevron-right.svg' alt=''/>
            </div>
            <div className='sec6_pay' style={{marginTop:'24px'}}>
              <h6 className='sec6_pay_title'>Do you give guarantee and after sales service?</h6>
              <img src='/images/chevron-right.svg' alt=''/>
            </div>
          </div>
        </div>
      </section>

      <section className='sec7'>
        {/* left div */}
          <div className='sec7_left'>
            <div className='sec7_expert'>
                <p className='sec7_c'>Creative Nerds</p>
            </div>

            <h4 className='sec7_team'>Meet Our Team</h4>
            <p className='sec7_desc'>We believe we can make a difference to this world, to this very earth on which we live.</p>
            <div className='img_arrow' style={{display:'flex',alignItems:'center',gap:'10px'}}>
              <img src='/images/arrowCircle.svg' alt=''/>
              <img src='/images/arrowCircleRight.svg' alt=''/>
            </div>
          </div>
          {/* right div  */}
          <div className='sec7_right'>
            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={100} animateOnce={true}> 
              <img src='/images/gbuyi.svg' alt=''/>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={100} animateOnce={true}> 
              <img src='/images/adebola.svg' alt=''/>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={100} animateOnce={true}> 
              <img src='/images/wahab.svg' alt=''/>
            </AnimationOnScroll>
          </div>
      </section>
      <section className='sec8'>
          <div className='wrapper sec8_p'>
                <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay]}
                spaceBetween={2}
                slidesPerView={5}
                autoplay={{delay:4000,pauseOnMouseEnter: true,}}
                loop={true}
                // pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                breakpoints={{
                 1000:{
                  slidesPerView: 5
                 },
                  640: {
                    slidesPerView: 2
                  }
                }}
                >
                  <SwiperSlide>
                     <img src='/images/luminous.svg' alt=''/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/images/genus.svg' alt=''/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/images/amaze.svg' alt=''/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/images/felicity.svg' alt=''/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/images/lento.svg' alt=''/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/images/kartel.svg' alt=''/>
                  </SwiperSlide>
                </Swiper>
              
          </div>
      </section>

      <section className='sec9'>
      <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={100} animateOnce={true}> 
        <img className='test_img' src='/images/building.jpg' alt=''/>
        </AnimationOnScroll>
        <div className='testmonial'>
          <div className='test_inner'>
            <img src='/images/quote.svg' alt='' />
            <p className='test_desc'>Aliquam lorem ante, dapibus in, viverra nisi quis, feugiat a, tellus. Phasellus viverra deo nulla ut metus varius qui laoreet. Quisque sum rutrum. Aenean imperdiet.</p>
            <img src='/images/photo.svg' alt='' style={{marginTop:'48px'}}/>
            <h4 className='test_name'>Amanda Johnson</h4>
            <p className='test_title'>Manager</p>
            <img src='/images/dots.svg' alt=''/>
          </div>
        </div>
      </section>
      <section className='sec10'>
          <div className='wrapper sec10_center'>
            <div className='sec10_news'>
                <p className='sec10_c'>READ THE ARTICLES</p>
            </div>
            <p className='sec10_blog'>Latest From The Blog</p>
            <div className='news_img'>
              <BlogItem 
              img='/images/blog1.jpg'
              date='09 June, 2021 '
              item='Electricity'
              title='6 Things You Must Know Before  Buying a UPS.'
              author='by Deoadmin'
              />

              <BlogItem 
              img='/images/blog2.jpg'
              date='09 June, 2021 '
              item='Solar'
              title='Which One is The Best Inverter Battery For Home?'
              author='by Deoadmin'
              />
              <BlogItem 
              img='/images/blog3.jpg'
              date='09 June, 2021 '
              item='Business'
              title='Which One is The Best Inverter Battery For Home?'
              author='by Deoadmin'
              />
            </div>
          </div>
      </section>
      <Footer />
    
    </div>
  )
}

export default LandingPage