import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";


import LandingPage from "./pages/landing/LandingPage";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import Project from "./pages/project/Project";
import Services from "./pages/services/Services";
import TechnicalService from "./pages/services/TechnicalService";
import SolarService from "./pages/services/SolarService";
import CapacityInverter from "./pages/services/CapacityInverter";
import Batteries from "./pages/services/Batteries";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Error from "./pages/error/Error";


function App() {
  return (
    <Router>
      <ScrollToTop >
        <Routes>
          {/* <Route path='/verify-email/:id' element={<EmailVerification /> } /> */}

         
          <Route path="/" element={<LandingPage />} />  
          
          <Route path="/commingSoon" element={<ComingSoon />} />  
          <Route path="/projects" element={<Project />} />  
          <Route path="/services" element={<Services />} />  
          <Route path="/technical-services" element={<TechnicalService />} />  
          <Route path="/solar-product" element={<SolarService />} />  
          <Route path="/capacity-inverters" element={<CapacityInverter />} />  
          <Route path="/batteries" element={<Batteries />} />  
          <Route path="/about" element={<About />} />  
          <Route path="/contact-us" element={<Contact />} />  
          <Route path="/404" element={<Error />} />  
         
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
