import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Hero from '../../components/common/Hero'
import Footer from '../../components/footer/Footer'
import ServiceAside from '../../components/services/ServiceAside'

function SolarService() {
  return (
    <div className='services'>
        <TopHeader />

        <Hero 
        subtitle='services'
        title='Solar Product'
        desc='This includes: Solar Panel 390Watts, DC Solar System.'
        img='/images/services/shero.jpg
        '
        />
        <section className='tech_main'>
            <div className='wrapper techflex'>
                <ServiceAside />
                <div className='serv_content_right'>
                    {/*  */}
                    <div className='serv_content1'>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Solar Panel 390Watts</h4>
                            <p className='servcon_desc'>
                            Truewish Energy PV Modules are crafted using state-of-the-art technology, 
                            driven by meticulous process<br/> management and quality assurance. Our unwavering 
                            commitment to Research & Development ensures that our <br/>
                            customers receive top-tier performance, 
                            quality, and dependability.<br/>
                             Moreover, our solar panel costs offer excellent value,
                            particularly considering the long-term benefits they provide.<br/>
                            Truewish Energy Modules serve as the cornerstone of our high-performance PV Energy Solutions. Built to be durable <br/>, reliable, and high-performing, our solar panels are available at cost-effective prices, promising substantial <br/> reductions in utility bills and carbon footprint. Each panel undergoes rigorous quality testing, is constructed with <br/> premium materials, and comes with an extended performance warranty.

                            <br/><br/>
                            Specifications for a 390W solar panel typically include:
                            <br/>
                            <li>Model -GM 390 Mono PERC</li>
                            <li>NOMINAL PEAK POWER(W) - 390W</li>
                            <li>Vmp (V) - 42.26</li>
                            <li>I mp (A) - 9.23</li>
                            <li>Voc (V) - 48.7</li>
                            <li>Solar Cell / Module Unit - 72 Cell</li>
                            <li>Module Efficiency - &gt;19%</li>
                            <li>Dimension (LXWXH) in mm 1960x990x35</li>
                            <li>Area - 1.94 m2</li>
                            </p>
                            <img src='/images/services/solarInveter.jpg' alt=''/>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Benefits</h5>
                            <ul className='serv_group_list'>
                                <li>Designed to withstand the harshest weather conditions, including rain and snow.</li>
                                <li>Top-tier MONO PERC solar cells featuring outstanding conversion efficiency, maximizing electricity generation from sunlight.</li>
                                <li>Robust low iron tempered glass with an anti-reflective coating enhances efficiency by capturing sunlight effectively.</li>
                                <li>Quick-curing, highly reflective, and UV-enhanced EVA encapsulation boosts durability and performance.</li>
                                <li>Robust yet lightweight aluminum alloy frame</li>
                                <li>Truewish Energy Panels incorporate a low-temperature coefficient to prevent efficiency decline in elevated temperatures.</li>
                            </ul>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                       
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>DC Solar System</h4>
                            <p className='servcon_desc'>
                            The Truewish Energy DC Home Lighting System offers an innovative lighting solution for households, particularly in rural areas lacking reliable grid power.
                            Additionally, the Truewish Energy Home Lighting System serves as an independent solution for urban residences and city dwellers aiming to lower their electricity costs and embrace intelligent energy-saving practices.
                            </p>
                            <img src='/images/services/Dc.jpg' alt=''/>
                        </div>
                    </div>

                    {/*  */}
                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Product Information</h5>
                            <ul className='serv_group_list'>
                                <li>Power Catcher (30A Solar Charge Controller) maximum power point tracking technology allows the controller to track the maximum power point of solar panels even in a complex environment. Compared with the traditional 30 Amp Solar Charge Controller tracking technology, it boasts faster response speed and higher tracking efficiency.</li>
                                <li>A built-in maximum power point tracking (MPPT) algorithm can significantly increase the energy utilization efficiency of the a photovoltaic system, which is about 15% to 20% higher than traditional PWM charging.</li>
                                <li>It provides an active charging voltage regulation feature. At battery open circuit or lithium battery BMS overcharge protection, the controller battery terminal will output the rated charging voltage value.</li>
                                <li>MPPT tracking efficiency is up to 99.9%.</li>
                                <li>Due to advanced digital power technology, the circuit energy conversion efficiency is as high as 98%.</li>
                                <li>Available in multiple battery types and support charging procedures of various types of batteries such as lithium batteries, colloidal battery, sealed battery, vented battery, lithium battery, etc.</li>
                                <li>A current-limited charging mode is available. When the power of the solar panel is too large and the charging current is higher than the rated valve, the controller automatically reduces the charging power so that the solar panel can operate at the rated charging current.</li>
                                <li>Support automatic identification of lead-acid battery voltage.</li>
                                <li>External LCD screen or BT module can be connected for viewing of equipment operating data and status, and modification of 30 Amp Solar Charge Controller parameters is supported.</li>
                                <li>Support standard Modbus protocol to meet communication needs on different occasions.</li>
                                <li>Built-in over-temperature protection mechanism ensures that when the temperature exceeds the set value of the device, the charging current decreases linearly with the temperature, thereby reducing the temperature rise of the controller and avoiding high-temperature damage.</li>
                                <li>Temperature compensation and automatic adjustment of charge and discharge parameters help to improve battery life.</li>
                                <li>Solar panel short circuit protection, battery open circuit protection and TVS lightning protection, etc.</li>
                            </ul>
                        </div>
                    </div>
                    {/*  */}
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default SolarService