import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Hero from '../../components/common/Hero'
import ServiceAside from '../../components/services/ServiceAside'
import Footer from '../../components/footer/Footer'
function Batteries() {
  return (
    <div className='services'>
        <TopHeader />

        <Hero 
        subtitle='services'
        title='Batteries'
        desc='This includes: Carbon, Graphite, Expander.'
        img='/images/services/bhero.jpg'
        />
        <section className='tech_main'>
            <div className='wrapper techflex'>
                <ServiceAside />
                <div className='serv_content_right'>
                    {/*  */}
                    <div className='serv_content1'>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Wet cell inverter battery</h4>
                            <p className='servcon_desc'>
                            Tubular batteries are a type of lead-acid battery that is designed with tubular positive plates instead of flat plates found in conventional lead-acid batteries.
                            </p>
                            <img style={{marginTop:"80px"}} src='/images/services/battery1.jpg' alt=''/>
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of the Wet cell battery 12V/200AH:</h5>
                            <ol className='serv_group_list'>
                                <li>High Acid Volumes</li>
                                <li>Tall Tubular Design</li>
                                <li>Low Maintenance, very low water topping up due to special design of plats with selenium low antimonial alloy</li>
                                <li>Spines are manufactured using state of art High-Pressure die Casting machine</li>
                                <li>High Purity, corrosion-resistant</li>
                                <li>Designed to have a life cycle over 1200 cycles at 80% DOD ( Depth of Discharge)</li>
                                <li>Electrolyte Level Indicator</li>
                                <li>Specially designed to support long and frequent power cuts</li>
                                <li>High performance and low maintenance</li>
                                <li>Designed for deep discharge</li>
                                <li>Faster Recharge</li>
                                <li>Longer Life</li>
                                <li>An ideal companion for Genus Home UPS and Inverters</li>
                            </ol>
                            
                        </div>
                    </div>
                    {/*  */}
                    <div className='serv_content1' style={{marginTop:'80px'}}>
                       
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Dry cell inverter battery</h4>
                            <p className='servcon_desc'>
                            Mecury Battery Inverter Battery 18Mah/12V
                            </p>
                            <img src='/images/services/battery2.jpg' alt=''/>
                        </div>
                    </div>

                    {/*  */}
                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of the Mecury Dry cell Inverter Battery 18Mah/12V:</h5>
                            <ol className='serv_group_list'>
                                <li>18amps 12V Inverter batteries</li>
                                <li>these are the best batteries for inverters and solar installations</li>
                                <li>good performance in hot and cold atmosphere</li>
                                <li>will last 3 or more hour when fully charged and used for optimum appliance load</li>
                                <li>thick cells</li>
                                <li>maintenance free</li>
                                <li>sealed batteries</li>
                                <li>Reputable  with high performance</li>
                                <li>Long life performance</li>
                                <li>Easy replacement for your UPS</li>
                            </ol>
                            
                        </div>
                    </div>
                   

                    {/*  */}

                    <div className='serv_content1' style={{marginTop:'80px'}}>
                        <div className='serv_cont1'>
                            <h4 className='servcon_title'>Lithium battery</h4>
                            <p className='servcon_desc'>
                            5KWH 24v Lifepo4 Lithium Deep Cycle Solar Battery For Inverter 
                            </p>
                            <img src='/images/services/battery3.jpg' alt=''/>
                        </div>
                    </div>

                    {/*  */}

                    <div className='serv_group'>
                        <div className='serv_group_inner'>
                            <h5 className='serv_group_title'>Key features of a 2.5KVA capacitor inverter may include:</h5>
                            <ol className='serv_group_list'>
                                <li>24V 100ah / 200ah lithium ion battery for energy storage system.</li>
                                <li>Waterproof : IP65 Protection level.</li>
                                <li>Floor or Power wall Mounting.</li>
                                <li>Support Connected in parallel mode for expansion (up to 12 pieces).</li>
                                <li>Built-in Battery management system(BMS).</li>
                                <li>Built-in air switch, double protection.</li>
                                <li>Iron Phosphate-lithium Battery Powerwall ：90% DOD , More than 6000 cycle times.</li>
                                <li>Long warranty period：5 years.</li>
                                <li>Convenient CAN&RS485 Communication.</li>
                                <li>Model LPBF24200-M</li>
                                <li>Size : 537*537*298</li>
                            </ol>
                            
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default Batteries