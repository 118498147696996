import React, { useEffect, useState } from 'react'
import './common.scss'
import ContentLoader from 'react-content-loader'
function Hero({subtitle,title,desc,img}) {
  const [loaded, setLoaded] = useState(false);

//   const handleImageLoad = () => {
//     setLoaded(true);
//   };

  const [imageAvailable, setImageAvailable] = useState(false);

  useEffect(() => {
    // Check if the image is immediately available
    const image = new Image();
    image.src = img;
    image.onload = () => {
      setImageAvailable(true);
      setLoaded(true);
    };
    image.onerror = () => {
      setImageAvailable(false);
      setLoaded(true);
    };
  }, [img]);
  return (
    <section className='proj_sec1 servic_top'>
        <div className='phero_container pflex'>
            <div className='proj_sec1_left serv_pro'>
                <h6 className='proj_sec1_subtitle'>{subtitle}</h6>
                <h4 className='proj_sec1_title'>{title}</h4>
                <p className='proj_sec1_desc'>{desc}</p>
            </div>
            

        <div className='image_container'>
          {(!imageAvailable || !loaded) && (
            <ContentLoader
              speed={2}
              width={720}
              height={600}
              viewBox="0 0 400 400"
              preserveAspectRatio="xMidYMid meet"
              style={{ display: loaded ? 'none' : 'block' }}
            >
            
              <rect x="0" y="0" width="100%" height="100%" fill="#f0f0f0" />
            </ContentLoader>
          )}
          {/* Actual image */}
          <img
            src={img}
            alt=''
            style={{ width: '100%', height: '100%', display: loaded ? 'block' : 'none' }}
            onLoad={() => setLoaded(true)}
          />
        </div>

            

       
           
        </div>
    </section>
  )
}

export default Hero