import React from 'react'
import TopHeader from '../../components/merchantLanding/header/TopHeader'
import Footer from '../../components/footer/Footer'
import Hero from '../../components/common/Hero'
import './contact.scss'

function Contact() {
  return (
    <div className='services'>
        <TopHeader />
        <Hero 
        subtitle='contact us'
        title='Get In Touch'
        desc='It represents a clean, green source of energy. Solar power is a great way to reduce your carbon footprint.'
        img='/images/hero5.jpg'
        />
        <section className='contact'>
            <div className='contact_info'>
                <div className='contact_content'>
                    <h3 className='contact_title'>Contact Info</h3>
                    <p className='contact_desc'>8 Babatunde Anjous, Off Admiralty Way Lekki phase 1, Lagos.</p>
                    <p className='contact_phone'>P:  +234 901 097 8618</p>
                    <p className='contact_phone'>E:  sales@truewishenergy.com</p>

                    <div className='contact_social'>
                        <img src='/images/cfacebook.svg' alt=''/>
                        <img src='/images/ctwitter.svg' alt=''/>
                        <img src='/images/clinkedin.svg' alt=''/>
                        <img src='/images/cyoutube.svg' alt=''/>
                    </div>
                </div>
                <div className='contact_form'>
                    <form className='form_container'>
                        <div className='input_double'>
                            <div className='contact_name'>
                                <p className='lblName'>your name</p>
                                <input className='input_form_contact' type='text' />
                            </div>
                            <div className='contact_email'>
                                <p className='lblName'>your email</p>
                                <input className='input_form_contact' type='text' />
                            </div>
                        </div>

                        <div className='contact_msg'>
                            <p className='lblName'>your message</p>
                            <textarea className='input_form_contact input_area'>

                            </textarea>
                        </div>
                        <div className='checkbox_input'>
                            <input type='checkbox' />
                            <p className='save_input'>Save my name, email, and website in this browser for the next time I comment.</p>
                        </div>
                        <button className='btn' style={{marginTop:'50px'}}>Send Message</button>
                    </form>
                </div>
            </div>
        </section>
        <section className='contact_sec4'>
            <img src='/images/contactmap.svg' alt=''/>
        </section>
        <Footer />
    </div>
  )
}

export default Contact