import React from 'react'

function TrustServiceItem({icon,title,desc}) {
  return (
    <div className='sec2_item'>
        <img className='sec2_img' src={icon} alt='' />
        <h1 className='sec2_title'>{title}</h1>
        <p className='sec2_desc'>{desc}</p>
    </div>
  )
}

export default TrustServiceItem