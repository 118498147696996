import React from 'react'

function AboutItem({title,desc}) {
  return (
    <div className='about_item'>
        <img src='/images/tickCircl.svg' alt=''/>
        <div className='about_item_content'>
            <h5 className='about_item_title'>{title}</h5>
            <p className='about_item_desc'>{desc}</p>
        </div>
    </div>
  )
}

export default AboutItem