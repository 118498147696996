import React from 'react'

function BlogItem({img,date,item,title,author}) {
  return (
    <div className='news_item'>
        <img className='blog_cover_img' src={img} alt='' />

        <div className='blog_content'>
        <div className='blog_time'>
            <img  src='/images/calendar.svg' alt='' />
            <span className='blog_date'>{date}</span>
            <span className='blog_date'> / </span>
            <span className=' blog_date blog_text'> {item} </span>
        </div>

        <h2 className='blog_title'>{title}</h2>
        <p className='blog_desc'>{author}</p>

        </div>
  </div>
  )
}

export default BlogItem